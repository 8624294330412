<template>
  <div style="margin-bottom: -25px;">
    <!-- 图片上传 -->
    <div v-if="fileType === 'img'">
      <a-upload :disabled="disabled" accept=".jpg,.jpeg,.gif,.png" listType="picture-card" :fileList="fileList"
        @preview="handlePreview" :customRequest="uploadCover" :remove="handleRemove" @change="handleChange"
        :show-upload-list="false" :openFileDialogOnClick="!isCutImg">
        <div v-if="fileList.length < 1" @click="openImgCutterModal">
          <a-icon type="plus" />
          <div class="ant-upload-text">点击上传</div>
        </div>
        <div v-else-if="!fileList[0].url">
          <div class="ant-upload-text">上传中</div>
        </div>
        <div class="image-echo" v-else @click="openImgCutterModal">
          <img :src="fileList[0].url" />
          <span class="image-echo-close" @click.stop="remove(fileList[0])"><a-icon type="close"
              :style="{ color: '#ffffff', fontSize: '16px' }" /></span>
          <div class="image-echo-change">
            <a-icon type="plus" :style="{ color: '#ffffff', fontSize: '24px' }" />
          </div>
        </div>
      </a-upload>
      <div style="color: #78809C;height: 15px;line-height: 1;">
        只能上传jpg/jpeg/gif/png文件,且不超过{{ size || 10 }}MB {{ (!myTipsTxt) ? "" : "（" + myTipsTxt + "）" }}
      </div>
    </div>

    <!-- 批量图片上传 -->
    <div v-else-if="fileType === 'batchImg'">
      <a-upload :disabled="disabled" accept=".jpg,.jpeg,.gif,.png" listType="picture-card" :multiple="true"
        :fileList="fileList" @preview="handlePreview" :customRequest="uploadCover" :remove="handleRemove"
        @change="handleChange">
        <div v-if="fileList.length < (max || 5)">
          Ctrl<span style="font-size: 21px;color: red;">+</span>点击
          <div class="ant-upload-text">批量上传</div>
        </div>
      </a-upload>
      <div style="color: #78809C;height: 15px;line-height: 1;">
        只能上传jpg/jpeg/gif/png文件,且不超过{{ size || 10 }}MB {{ (!myTipsTxt) ? "" : "（" + myTipsTxt + "）" }}
      </div>
    </div>

    <!-- 批量文件上传 -->
    <a-upload v-else-if="fileType === 'batchFile'" :disabled="disabled" :fileList="fileList" :accept="accept"
      :multiple="true" :customRequest="uploadCover" :remove="handleRemove" @change="handleChange">
      <a-button>
        <a-icon type="upload" />批量上传文件
      </a-button>
      <span style="margin-left: 10px;color: red;">最大支持上传 {{ size || 100 }} MB</span>
    </a-upload>

    <!-- 文件上传 -->
    <a-upload v-else :disabled="disabled" :accept="accept" :fileList="fileList" :customRequest="uploadCover"
      :remove="handleRemove" @change="handleChange">
      <a-button>
        <a-icon type="upload" />
        {{ myBtnTxt || "上传文件" }}
      </a-button>
      <span style="margin-left: 10px;color: red;">最大支持上传 {{ size || 100 }} MB</span>
    </a-upload>

    <!-- 隐藏字段 -->
    <a-form-item :style="{ 'margin-top': fileType === 'img' || fileType === 'batchImg' ? '-15px;' : '' }">
      <a-select v-if="fileType === 'batchImg'" style="display:none" mode="multiple" v-decorator="[fieldCode,
      {
        initialValue: value,
        rules: [
          { required: required || false, message: message || '请上传封面图！' },
          { type: 'array', max: max || 5, message: '最多支持上传' + (max || 5) + '张！' }
        ]
      }
    ]" />
      <a-select v-else-if="fileType === 'batchFile'" style="display:none" mode="multiple" v-decorator="[fieldCode,
      {
        initialValue: value,
        rules: [
          { required: required || false, message: message || '请上传文件！' },
          { type: 'array', max: max || 5, message: '最多支持上传' + (max || 5) + '个！' }
        ]
      }
    ]" />
      <a-input v-else type="hidden" v-decorator="[fieldCode,
      {
        initialValue: value,
        rules: [{ required: required || false, message: message || '请上传封面图！' }]
      }
    ]" />
    </a-form-item>

    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>

    <!-- 图片裁剪器 -->
    <a-modal v-model="visibleCutImg" title="图片裁剪" ok-text="确认" cancel-text="取消" @ok="uploadImg('blob')" :width="960"
      :confirmLoading="isCutImgUploading" @cancel="handleCancel">
      <div class="cropper-content">
        <div class="cropper-box">
          <div class="cropper">
            <vueCropper ref="cropper" :img="cutImg" :outputSize="options.outputSize" :outputType="options.outputType"
              :info="options.info" :canScale="options.canScale" :autoCrop="options.autoCrop"
              :autoCropWidth="options.autoCropWidth" :autoCropHeight="options.autoCropHeight" :fixed="options.fixed"
              :fixedNumber="options.fixedNumber" :full="options.full" :fixedBox="options.fixedBox"
              :canMove="options.canMove" :canMoveBox="options.canMoveBox" :original="options.original"
              :centerBox="options.centerBox" :high="options.high" :infoTrue="options.infoTrue"
              :maxImgSize="options.maxImgSize" :enlarge="options.enlarge" :mode="options.mode" @realTime="realTime"
              @imgLoad="imgLoad">
            </vueCropper>
          </div>
          <!--底部操作工具按钮-->
          <div class="footer-btn">
            <span class="cover-btn">
              <label for="uploads">选择封面</label>
              <input v-if="visibleCutImg" type="file" id="uploads" style="position:absolute; clip:rect(0 0 0 0);"
                accept="image/png, image/jpeg, image/gif, image/jpg" @change="selectImg($event)">
            </span>
            <a-button size="small" type="danger" plain icon="zoom-in" @click="changeScale(1)">放大</a-button>
            <a-button size="small" type="danger" plain icon="zoom-out" @click="changeScale(-1)">缩小</a-button>
            <a-button size="small" type="danger" plain icon="undo" @click="rotateLeft">左旋转</a-button>
            <a-button size="small" type="danger" plain icon="redo" @click="rotateRight">右旋转</a-button>
          </div>
        </div>

        <!--预览效果图-->
        <div class="show-preview">
          <p class="tip" v-if="!previewCutImg.url">预览区</p>
          <div :style="previewCutImg.div" class="preview">
            <img :src="previewCutImg.url" :style="previewCutImg.img">
          </div>
        </div>

      </div>
    </a-modal>
  </div>
</template>
<script>
/**
 * @author dengwen
 * 共用上传文件组件
 * 引入  import { rUpload } from "@/components";
 * 注：编辑不要设置 setFieldsValue，避免与组件的赋值冲突.
 * <a-form-item> 必填需要加上 required
 *
  <a-form-item required
    label="封面"
    v-bind="formItemLayout"
  >
    <rUpload
      fileType="img"
      fieldCode="coverImg"
      :max="5"
      :form="form"
      :required="true"
      message="请上传封面图！"
      :key="String(newRecord.coverImg)"
      :value="newRecord.coverImg"
    />
  </a-form-item>
 */
import { resMsg } from "@/utils/http";
import { randomUUID } from "@/utils/util"
import { isBlank } from "@/utils";
import api from "@/api"
import { VueCropper } from "vue-cropper"
const options = {
  outputSize: 1, // 裁剪生成图片的质量 (0.1 - 1)
  outputType: "jpg", // 裁剪生成图片的格式 (jpeg || png || webp)
  info: true, // 裁剪框的大小信息 (true || false)
  canScale: true, // 图片是否允许滚轮缩放 (true || false)
  autoCrop: true, // 是否默认生成截图框 (true || false)
  autoCropWidth: "100%", // 默认生成截图框宽度 (0~max)
  autoCropHeight: "100%", // 默认生成截图框高度 (0~max)
  fixed: false, // 是否开启截图框宽高固定比例 (true | false)
  fixedNumber: [1, 1], // 截图框的宽高比例 ([宽度, 高度])
  full: true, // 是否输出原图比例的截图 (true | false)
  fixedBox: false, // 固定截图框大小 不允许改变 (true | false)
  canMove: true, // 上传图片是否可以移动 (true | false)
  canMoveBox: true, // 截图框能否拖动 (true | false)
  original: false, // 上传图片按照原始比例渲染 (true | false)
  centerBox: false, // 是否按照设备的dpr 输出等比例图片 (true | false)
  high: true, // 是否按照设备的dpr 输出等比例图片 (true | false)
  infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高 (true | false)
  maxImgSize: 3000, // 限制图片最大宽度和高度 (0-max)
  enlarge: 1, // 图片根据截图框输出比例倍数 0-max(建议不要太大不然会卡)
  mode: "contain" // 图片默认渲染方式 (contain , cover, 100px, 100% auto)
}
export default {
  /**
   * form 验证表单 必填
   * value 字段值 选填
   * size 文件大小 以MB为单位 选填
   * max 图片个数，不填写默认5 选填
   * fieldCode 字段编号 必填
   * fileType 类型 img:图片 batchImg:批量图片 file:文件 file:批量文件 必填
   * required 是否必填 true | false 选填
   * message 提示消息 选填
   */
  // props: ["form", "value", "size", "max", "fieldCode", "fileType", "required", "message", "isCutImg", "cutImgRate"],
  props: {
    form: Object,
    value: [String, Number, Array],
    size: Number,
    max: Number,
    fieldCode: String,
    fileType: String,
    required: Boolean,
    message: String,
    accept: String,
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否开启上传图片前裁剪图片
    isCutImg: {
      type: Boolean,
      default: false
    },
    // 图片裁剪器配置选项
    cutImgOption: {
      type: Object
    },
    myBtnTxt: String,
    myTipsTxt: String
  },
  components: {
    VueCropper
  },
  data() {
    return {
      ossPath: process.env.VUE_APP_OSS_DOMAIN,
      previewImage: "", // 当前预览的图片
      previewVisible: false, // 封面预览弹窗
      fileUrl: "/file/upload", // 上传请求地址
      fileList: [],
      fileSize: {
        file: 1024 * 1024 * 100,
        batchImg: 1024 * 1024 * 10,
        img: 1024 * 1024 * 10
      },
      fileSizeMessage: {
        file: "请选择文件大小不能超过",
        batchImg: "请选择图片大小不能超过",
        img: "请选择图片大小不能超过"
      },
      visibleCutImg: false,
      options,
      cutImg: "",
      previewCutImg: "",
      isCutImgUploading: false
    }
  },
  computed: {
    fileTypes() {
      if (this.accept) {
        let arr = this.accept.split(',');
        return arr;
      }
      return ["jpg", "jpeg", "gif", "png"]
    }
  },
  created() {
    // 图片裁剪器配置
    Object.assign(this.options, this.cutImgOption);
  },
  mounted() {
    if (!isBlank(this.value)) {
      let url;
      if (this.fileType.indexOf("batch") !== -1) {
        for (const i in this.value) {
          url = this.value[i].indexOf("http") !== -1 ? this.value[i] : this.ossPath + this.value[i]
          this.fileList.push({
            uid: new Date().getTime() + i,
            status: "done",
            name: this.value[i],
            url: url
          })
        }
      } else {
        url = this.value.indexOf("http") !== -1 ? this.value : this.ossPath + this.value
        this.fileList.push({
          uid: new Date().getTime(),
          status: "done",
          name: this.value,
          url: url
        })
      }
    }
  },
  methods: {
    handleRemove(file) {
      this.handleChangeFile(file.uid)
    },
    remove(file) {
      this.fileList = [];
      this.handleChangeFile(file.uid);
    },
    handlePreview(file) {
      this.previewImage = file.url;
      this.previewVisible = true;
    },
    handleChange({ file, fileList }) {
      let message = this.validationFile(file);
      if (fileList.length > (this.max || 5)) {
        let unit = "个! "
        if (this.fileType === "batchImg" || this.fileType === "img") {
          unit = "张！"
        }
        this.$message.error("最多支持上传" + (this.max || 5) + unit);
      } else if (!isBlank(message)) {
        this.$message.error(message);
      } else {
        this.fileList = fileList;
      }
    },
    // 自定义封面的上传事件
    uploadCover(data) {
      if (this.fileList.length + 1 > (this.max || 5)) return
      if (!isBlank(this.validationFile(data.file))) return;
      if (this.fileType === "img" && this.fileList[0]) delete this.fileList[0].url
      let formData = new FormData();
      formData.append("file", data.file);
      data.onProgress();
      api.uploadFile(formData, "", this.fileUrl).then((res) => {
        if (res.code === 200) {
          data.onSuccess(); // 上传成功
          let url = res.data?.url.indexOf("http") !== -1
            ? res.data?.url : this.ossPath + res.data?.url
          if (this.fileList.length > 1 && this.fileType.indexOf("batch") === -1) {
            this.fileList[this.fileList.length - 1].url = url
            this.fileList.shift()
          } else {
            this.setFileListUrl(url, data.file.name);
          }
          this.handleChangeFile(-1)
          resMsg("success", res.msg, "上传成功！");
        } else {
          data.onError(); // 上传失败
          resMsg("error", res.msg, "上传失败！");
        }
      })
    },

    // 设置数据
    handleChangeFile(uid) {
      let list = []
      for (const i in this.fileList) {
        if (this.fileList[i].uid !== uid && this.fileList[i].url) {
          list.push(this.fileList[i].url.replace(this.ossPath, ""))
        }
      }
      if (this.fileType === "batchFile") {
        this.form.setFieldsValue({ [this.fieldCode]: list });
      } else if (this.fileType === "batchImg") {
        this.form.setFieldsValue({ [this.fieldCode]: list });
      } else if (list.length > 0) {
        this.form.setFieldsValue({ [this.fieldCode]: list[0] });
      } else {
        this.form.setFieldsValue({ [this.fieldCode]: "" });
      }
    },

    // 赋值fileList.url
    setFileListUrl(url, fileName) {
      for (const i in this.fileList) {
        if (this.fileList[i].name === fileName && isBlank(this.fileList[i].url)) {
          this.fileList[i].url = url;
        }
      }
    },

    // 验证图片大小或者格式
    validationFile(file) {
      let message;
      let size = !isBlank(this.size) ? this.size * 1024 * 1024 : this.fileSize[this.fileType];
      // 有些文件选择完在file.type可能为空，例如raw。所以通过截取name后面的.作为文件类型判断。
      let type = file.name.slice(file.name.lastIndexOf(".") + 1).toLowerCase();
      if (!isBlank(type)) {
        // let type = file.type.substring(file.type.indexOf("/") + 1);
        if (this.fileType !== "file" && this.fileType !== "batchFile" && !this.fileTypes.includes(type)) {
          message = "请选择文件格式.jpg.jpeg.gif.png";
        } else if (file.size > size) {
          message = this.fileSizeMessage[this.fileType] + size / (1024 * 1024) + "MB！";
        }
      }
      return message;
    },

    // 打开图片裁剪
    openImgCutterModal() {
      if (this.isCutImg) {
        this.visibleCutImg = true;
      }
    },

    // 图片缩放 - 图片裁剪器
    changeScale(num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },

    // 向左旋转 - 图片裁剪器
    rotateLeft() {
      this.$refs.cropper.rotateLeft()
    },

    // 向右旋转 - 图片裁剪器
    rotateRight() {
      this.$refs.cropper.rotateRight()
    },

    // 实时预览函数 - 图片裁剪器
    realTime(data) {
      this.previewCutImg = data
      // 保证预览框定位
      let trueWidth = this.$refs.cropper.trueWidth;
      let trueHeight = this.$refs.cropper.trueHeight;
      // 宽高比
      let proportion = this.$refs.cropper.fixedNumber[0] / this.$refs.cropper.fixedNumber[1];
      let scale = this.$refs.cropper.scale; // 缩放比例
      if (trueWidth >= trueHeight) {
        this.options.autoCropWidth = trueWidth * scale;
        this.options.autoCropHeight = trueWidth * scale / proportion;
      } else {
        this.options.autoCropWidth = trueHeight * scale * proportion;
        this.options.autoCropHeight = trueHeight;
      }
    },

    // 选择图片 - 图片裁剪器
    selectImg(e) {
      let file = e.target.files[0]
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        this.$message({
          message: "图片类型要求：jpeg、jpg、png",
          type: "error"
        });
        return false
      }
      // 转化为blob
      let reader = new FileReader()
      reader.onload = (e) => {
        let data
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        this.cutImg = data
      }
      // 转化为base64
      reader.readAsDataURL(file)
    },

    // 上传裁剪的图片 - 图片裁剪器
    uploadImg(type) {
      if (type === "blob") {
        this.isCutImgUploading = true;
        // 获取截图的blob数据
        this.$refs.cropper.getCropBlob(async (data) => {
          let formData = new FormData();
          formData.append("file", data, "DX.jpg")

          api.uploadFile(formData, "", this.fileUrl).then((res) => {
            if (res.code === 200) {
              let url = res.data.indexOf("http") !== -1 ? res.data : this.ossPath + res.data;
              let f = {
                uid: randomUUID(),
                name: randomUUID(),
                status: "done",
                url
              };
              if (this.fileType === "img") {
                // 单张图片
                this.fileList = [f];
              } else {
                // 多张图片
                this.fileList = [...this.fileList, f];
              }
              this.visibleCutImg = false;
              this.handleChangeFile(-1);
              resMsg("success", res.msg, "上传成功！");
            }
          }).finally(() => { this.isCutImgUploading = false; });
        })
      }
    },
    // 截图框图片加载回调
    imgLoad() {
      let trueWidth = this.$refs.cropper.trueWidth;
      let trueHeight = this.$refs.cropper.trueHeight;
      // 宽高比
      let proportion = this.$refs.cropper.fixedNumber[0] / this.$refs.cropper.fixedNumber[1];
      let scale = this.$refs.cropper.scale; // 缩放比例
      if (trueWidth >= trueHeight) {
        this.options.autoCropWidth = trueWidth * scale;
        this.options.autoCropHeight = trueWidth * scale / proportion;
      } else {
        this.options.autoCropWidth = trueHeight * scale * proportion;
        this.options.autoCropHeight = trueHeight;
      }
    },
    // 关闭截图弹框
    handleCancel() {
      // this.$refs.cropper.clearCrop();
      this.cutImg = ""; // 清除截图框和图片
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .ant-upload.ant-upload-select-picture-card>.ant-upload {
    padding: 0;
  }

  .ant-upload-picture-card-wrapper {
    display: block;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 96px;
    height: 96px;
  }
}

.batch-img {
  ::v-deep {
    .favicon-uploader {
      .ant-upload-select-picture-card {
        .ant-upload.ant-upload-select-picture-card {
          width: 62px !important;
          height: 62px !important;
        }
      }
    }

    .ant-upload-picture-card-wrapper {
      margin-top: 10px;

      .ant-upload-list-picture .ant-upload-list-item,
      .ant-upload-list-picture-card .ant-upload-list-item {
        padding: 0;
        width: 96px;
        height: 96px;
      }
    }

    .ant-upload-list-picture-card-container {
      margin-right: 20px;

      .ant-upload-list-item-actions {
        left: 100% !important;
        top: 0 !important;
        transform: translate(-50%, -50%) !important;
        opacity: 1;

        &>a {
          display: none !important;
        }

        .anticon-delete {
          width: 20px;
          height: 20px;
          background: url('/assets/img/icon/close.png') no-repeat;
          background-size: 100%;

          svg {
            display: none;
          }
        }
      }
    }
  }
}

.image-echo {
  position: relative;

  img {
    width: 96px;
    height: 96px;
  }

  .image-echo-close {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    background: #F56C6C;
    border-radius: 10px;
    z-index: 2;
  }

  .image-echo-change {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($color: #000000, $alpha: 0.6);
    display: none;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    .image-echo-change {
      display: flex;
    }
  }
}

.cropper-content {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;

  .cropper-box {
    flex: 1;
    width: 100%;

    .cropper {
      width: 98%;
      height: 300px;
    }

    .footer-btn {
      width: 98%;
      margin-top: 1em;
      position: relative;
      text-align: right;

      .cover-btn {
        text-align: right;
        height: 24px;
        line-height: 24px;
        position: absolute;
        top: 0;
        left: 0;
        float: left;
        color: #fff;
        background-color: #1890ff;
        border-color: #1890ff;
        border-radius: 4px;
        width: 80px;
        text-align: center;
        cursor: pointer;

        label {
          cursor: pointer;
        }
      }

      .ant-btn-danger {
        margin-left: 10px;
      }
    }
  }

  .show-preview {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;

    .tip {
      line-height: 300px;
    }

    .preview {
      overflow: hidden;
      border: 1px solid #67c23a;
      background: #cccccc;
    }
  }
}
</style>
